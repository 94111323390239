import { colorConst } from '../tokens/new/color-const';

export const lightTheme = {
    brand: colorConst.torchRed, // Цвет логотипа
    accentActive: colorConst.blueberryDark, // Используется для иконок и состояния фокуса
    accentPositive: colorConst.appleNormal, // Цвет успешных действий. Используется для иконок appleNormal
    accentWarning: colorConst.bananaDark, // Цвет предупреждения. Используется для иконок
    accentNegative: colorConst.orangeNormal, // Цвет ошибки. Используется для иконок и состояния ошибки
    accentActiveInverted: colorConst.blueberryLight, // Используется для иконок и состояния фокуса на инвертированном бэкграунде.
    accentPositiveInverted: colorConst.appleLight, // Цвет успешных действий. Используется для иконок на инвертированном бэкграунде.
    accentWarningInverted: colorConst.marigoldYellow, // Цвет предупреждения. Используется для иконок на инвертированном бэкграунде.
    accentNegativeInverted: colorConst.salmon, // Цвет ошибки. Используется для иконок и состояния ошибки на инвертированном бэкграунде.
    textHeadline: colorConst.greyscale900, // Цвет заголовка
    textPrimary: colorConst.greyscale800, // Основной текст
    textSecondary: colorConst.greyscale500, // Цвет дополнительного текста
    textTertiary: colorConst.greyscale400, // Цвет масок
    textInverted: colorConst.alabaster, // Текст для тултипа, тоста и снэкбара
    textPositive: colorConst.malachite, // Цвет успешных действий
    textNegative: colorConst.tiaMaria, // Цвет текста ошибки и негативных действий
    textPrimaryLink: colorConst.scienceBlue, // Цвет ссылок
    textPrimaryLinkHover: colorConst.blueberryDark, // Цвет ссылок и бордера при наведении
    textPrimaryLinkVisited: colorConst.dodgerBlue, // Цвет visited ссылок и бордера
    textPrimaryLinkInverted: colorConst.blueberryLight, //Основные ссылки на инвертированном бэкграунде.
    textSecondaryLink: colorConst.greyscale500, //Цвет второстепенных ссылок
    textSecondaryLinkHover: colorConst.greyscale300, //Цвет второстепенных ссылок и бордера при наведении
    textSecondaryLinkVisited: colorConst.grayChateau, // Цвет второстепенных visited ссылок и бордера
    textVisitedLink: colorConst.plumNormal, //Цвет visited ссылок
    textInactiveTabBar: colorConst.paleSky, //Неактивный цвет таба
    backgroundPrimary: colorConst.greyscale0, // Основной фон темы.
    backgroundStroke: colorConst.linkWater50, // Сепараторы и обводки.
    backgroundPrimaryElevated: colorConst.greyscale0, // Первый уровень над фоном: карточки, инпут, таббар.
    backgroundSecondary: colorConst.greyscale100, // Второстепенный фон темы.
    backgroundSecondaryElevated: colorConst.greyscale100, // Тот же первый уровень, но над Secondary Background.
    backgroundModal: colorConst.greyscale100, // Второй уровень над фоном, например, выпадающие меню и шторки.
    backgroundInverted: colorConst.greyscale800, // Фон тултипа, тоста и снэкбара.
    backgroundOverlay: colorConst.cinder40, // Затемнение фона.
    backgroundHover: colorConst.linkWater25, // Ховер. Используется над фоном элемента, но под его контентом.
    backgroundHoverInverted: colorConst.solitude20, // Ховер кнопки снэкбара
    backgroundNavbarIos: colorConst.white92, // Навбар iOS. Использовать только совместно с Background Blur.
    backgroundDisabled: colorConst.whisper, // Цвет фона для неактивных инпута, дропдауна, текстареа.
    backgroundStrokeDisabled: colorConst.linkWater25, // Цвет обводки фона для неактивных инпута, дропдауна, текстареа.
    backgroundLower: colorConst.greyscale100, // Альтернативный основной фон темы.
    controlPrimaryActive: colorConst.torchRed, // Выбранный, активный контрол
    controlSecondaryActive: colorConst.greyscale800, // Выбранный, активный второстепенный контрол
    controlTertiaryActive: colorConst.greyscale100, // Цвет второстепенной кнопки
    controlInactive: colorConst.linkWater50, // Невыбранный контрол, выключенный Switch, предвыбранные Radio и Checkbox
    controlAlternative: colorConst.greyscale0, //greyscale0 Цвет фона дефолтного состояния у Radio и Checkbox, Secondary Inverted Button
    controlAlternativeStrokeHover: colorConst.mischka, // Цвет обводки Radio и Checkbox при наведении
    controlActiveTabBar: colorConst.torchRed, // Активный пункт в таббаре
    controlInactiveTabBar: colorConst.regentGray, // Неактивный пункт в таббаре.
    controlStroke: colorConst.regentGray, // Обводка контролов
    iconPrimary: colorConst.greyscale800, // Цвет иконок в навбаре и второстепенных кнопках
    iconSecondary: colorConst.regentGray, // Цвет интерактивных иконок
    iconTertiary: colorConst.greyscale300, // Цвет декоративных иконок
    premium: 'linear-gradient(48deg, #9BD5FF 6.25%, #9B93FF 44.27%, #9A8DFF 58.33%, #7C86FE 84.9%)', // Цвет кнопки, бейджей, логотипа
    blendHover: 'multiply', // Режим смешивания ховера с фоном
    blendHoverInverted: 'normal', // Режим смешивания ховера с фоном кнопки снэкбара
};
